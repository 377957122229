import React, { useContext, useState } from "react"
import Ctx from "../../context"
// components
import CardFamily from "../CardFamily"
import GridSection from "../GridSection"
import Loading from "../Loading"
// functions
import { handleGetMatched, handleSuggestions } from "../../utils"
import CardLast from "../CardLast"
import styles from "../FamiliesGrid/styles.module.scss"
import ButtonCustom from "../ButtonCustom"

const ClosedCase = ({ data }) => {
  return (
    <ul>
      {data.map((familyData, index) => (
        <li key={`suggestions-${index}`}>
          <CardFamily data={familyData} isClosed={true} />
        </li>
      ))}
      <li>
        <CardLast
          title="Help someone else today"
          description="Get matched with a household automatically"
          buttonLabel="Get Matched"
          handleClick={handleGetMatched}
        />
      </li>
    </ul>
  )
}

const ClosedCases = () => {
  const { closedFamilies, allFamilies } = useContext(Ctx)
  const [numToShow, setNumToShow] = useState(5)
  const [loading, setLoading] = useState(null)

  const next5 = () => {
    setLoading(true)
    setNumToShow(numToShow + 5)
    setLoading(false)
  }

  if (!closedFamilies.value) {
    // context not loaded yet
    return <Loading />
  }

  if (closedFamilies.value.length > 0) {
    return (
      <GridSection
        title={`Our neighbors thank you for your generosity`}
        id="closed-needs"
        bgColor="cream"
      >
        {closedFamilies && (
          <ClosedCase
            data={handleSuggestions(closedFamilies.value, numToShow)}
          />
        )}
        {closedFamilies.value.length > numToShow && (
          <div className={styles.button}>
            <ButtonCustom
              handleClick={next5}
              hierarchy="secondary"
              size="large"
              loading={loading}
            >
              Meet more Households
            </ButtonCustom>
          </div>
        )}
      </GridSection>
    )
  } else {
    return null
  }
}

export default ClosedCases
