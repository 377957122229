import React, { useState, useEffect } from "react"

// components
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Introduction from "../components/Introduction"
import HowItWorks from "../components/HowItWorks"
import FamiliesGrid from "../components/FamiliesGrid"
import Partners from "../components/Partners"
import ClosedCases from "../components/ClosedCases"
import EmailSection from "../components/EmailSection"
import Subscribe from "../components/Subscribe"
import WaysToHelp from "../components/WaysToHelp"
import Corporates from "../components/Corporates"
import News from "../components/News"
import NewsCarousel from "../components/NewsCarousel"
// import GivingCards from "../components/GivingCards"

const Home = () => {
  // const [givingCardOpen, setGivingCardOpen] = useState(true)

  return (
    <>
      <SEO />
      <Hero />
      {/*NOTE: if we reintroduce GivingCards, it used to use the klaviyo public key to send events. All the other usages are on the backend, so we swapped them to use the private key. Either introduce two flows, or plumb this through the backend*/}
      {/*<GivingCards*/}
      {/*  givingCardOpen={givingCardOpen}*/}
      {/*  closeGivingCard={() => setGivingCardOpen(false)}*/}
      {/*/>*/}
      <FamiliesGrid />

      {/* <WaysToHelp /> */}
      {/* <Introduction />
      <HowItWorks />
      <FamiliesGrid /> */}
      <EmailSection />
      <Partners />
      <Corporates />
      <NewsCarousel />
      {/* <ClosedCases /> */}
      <Subscribe />
    </>
  )
}

export default Home
