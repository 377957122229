import React from "react"
import styles from "./styles.module.scss"

function News() {
  return (
    <section className={styles.news}>
      <div>
        <h1 id={styles.title}>News</h1>
        <h2>News Here</h2>
      </div>
    </section>
  )
}

export default News
