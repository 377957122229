import React from "react"
import Icon from "../Icon"
import ShareButtons from "../ShareButtons"

import styles from "./styles.module.scss"

const steps = [
  {
    icon: "search",
    text: "Local service partners identify people in need",
  },
  {
    icon: "housing",
    text: "Donors select needs they can support",
  },
  {
    icon: "money",
    text: "Donations go directly to service partners",
  },
  {
    icon: "unclassified",
    text: "Service partners get donations to people in need ",
  },
]

const Step = ({ step }) => (
  <article className={styles.step}>
    <Icon aesthetic="simple" icon={step.icon} />
    <p className="body-copy">{step.text}</p>
  </article>
)

const HowItWorks = () => {
  return (
    <section className={styles.howItWorks}>
      <h1 className="header">How it works</h1>
      <div className={styles.steps}>
        {steps.map((step, index) => (
          <Step key={index.toString()} step={step} />
        ))}
      </div>
      <ShareButtons />
    </section>
  )
}

export default HowItWorks
